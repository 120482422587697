*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.img {
  border-radius: 50px;
}

.movieRow {
  width: 100%;
  margin-bottom: 30px;
  background: #FDD201;
}

.movieRow--listarea {
    overflow-x: hidden;
}

.movieRow--list {
  transition: all ease 0.5s;
}

.movieRow--item {
  display: inline-block;
  width: 150px;
}


.movieRow--item img {
    width: 100%;
    height: 10vh;
    transform: scale(0.9);
    transition: all ease 0.2s;
}

.movieRow--item img:hover {
    transform: scale(1);
}

.movieRow--left {
    position: absolute;
    width: 50px;
    height: 170px;
    background-color:rgba(255, 255, 255, 0);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    margin-left: 16%;
}

.movieRow--right {
  position: absolute;
  width: 50px;
  height: 170px;
  background-color:rgba(255, 255, 255, 0);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  margin-right: 16%;
}

.imgBoder {
  border-radius: 60px;
  opacity: 0.6;
}

.movieRow--left {
    left: 0;
}

.movieRow--right {
    right: 0;
}

.movieRow:hover .movieRow--left {
  opacity: 1;
 }
.movieRow:hover .movieRow--right {
  opacity: 1;
}

@media screen and (max-width: 780px) {
    
  .movieRow1 {
      display: none;
    }
  .movieRow--left {
    position: absolute;
    width: 50px;
    height: 170px;
    background-color:rgba(255, 255, 255, 0);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    margin-left: 0%;
  }

  .movieRow--right {
    position: absolute;
    width: 50px;
    height: 170px;
    background-color:rgba(255, 255, 255, 0);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    margin-right: 0%;
  }
}

.serach img {
  width: 10%;
  background: transparent;

  @media screen and (max-width: 923px)  {
    width: 40%;
    height: 5vh;
  }
}

.serach p {
  width: 40%;
  font-size: 1vwpx;

  @media screen and (max-width: 923px)  {
    font-size: 1.6vw;
  }
}
